//called from App.js
import { useState } from "react";
import styles from "../Splash.module.css";
import CarousalCard from "../components/CarousalCard/CarousalCard";
import Loader from "../Loader";
import Carousal1 from "../Carousal_1.svg";
import Carousal2 from "../Carousal_2.svg";
import Carousal3 from "../Carousal_3.svg";
import Carousal4 from "../Carousal_4.svg";
import pills from "../pills.svg";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Dialog, DialogContent } from "@material-ui/core";
import wellpas_logo from "../wellpas_logo.png";
import LoginHelp from "./LoginHelp";
import Status from "../components/Status";
import RequestAccessNew from "./RequestAccessNew";
import CarouselComponent from "../components/CarousalComponent/CarousalComponent";
import { useNavigate } from "react-router-dom";
import TextfieldStyled from "../components/TextfieldStyled/TextfieldStyled";
import { Alert } from "@material-ui/lab";
import { Error } from "@material-ui/icons";
// called in App.js -(it receives three props : apphandler, tokenhandler, credentialidhandler)
function Login(props) {
  const navigate = useNavigate();
  //The initial state of loginform is an object with a default username and password.
  const [loginform, setloginform] = useState({
    username: "",
    password: "",
  });
  //used to show loading component ,initially its not shown
  const [loading, setloading] = useState(false);
  //dialogVisibility is used to show dialog box, initally is not shown
  const [dialogVisibility, setdialogVisibility] = useState(false);
  //it used to set dialog box status wheather its request access or login assistance
  const [dialogType, setdialogType] = useState();
  //status is used to pass appropriate to status component , no status message is shown initially.
  const [status, setstatus] = useState("invisible");
  //used to send message to status component
  const [message, setmessage] = useState("");

  const [messageStatus, setmessageStatus] = useState("");

  //handlechange: event handler for the input fields in the login form
  const handlechange = (event) => {
    let kew = { ...loginform }; //creates a copy of the loginform object
    //updates the input fields based on the event.target.name,
    kew[event.target.name] = event.target.value;
    // then updates the loginform state using setloginform
    setloginform(kew);
  };

  const handlesubmit = (event) => {
    console.log("click");
    //Loding is shown when login process is in progress
    setloading(true);
    //setprogress(25); //its not used yet

    // requestoptions object containing the username and password to be sent to the backend server using the Fetch API
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        //converts js object in json string
        //The username and password are taken from the loginform state.
        username: loginform.username,
        password: loginform.password,
      }),
    }; //the username and password is send to backend via api

    /* fetch function is used to make a POST request to the backend API with the provided 
        requestOptions. It sends the username and password to the server for authentication.
    */
    setmessageStatus("");
    fetch(`${process.env.REACT_APP_ENDPOINT}/credentials/login`, requestOptions)
      .then((response) => {
        //setprogress(50);
        return response.json();
      })
      .then((data) => {
        //Check if the response data is present
        if (data) {
          /*Check if the response data is success; The api returns an object like 
          {
              "jwt": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBY2Nlc3NUb2tlbiIsImp0aSI6IjViMDAxZjQ4LTlkMTItNDgzYi1hMjQxLWRhMTcwOTQyNGM4MCIsImlhdCI6IjcvMjQvMjAyMyA0OjQ2OjIxIEFNIiwiSWQiOiIyIiwidXNlcm5hbWUiOiJBZG1pbnN0cmF0b3IiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJBZG1pbiIsImV4cCI6MTY5MDI2MDM4MSwiaXNzIjoiQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJDbGllbnQifQ.0qEqkeKBXWVrH_gGSOaNhuHeag8rn23J78aVX2ao91U",
              "usergroup": "Admin",
              "id": 2,
              "resp": "success"
          }*/

          // Check if data.response is success which means the username and password are correct.
          if (data.resp === "success") {
            //Set JWT token in APP.js  to token via tookhandler
            props.tokenhandler(data.jwt);
            localStorage.clear();
            localStorage.setItem("token", data.jwt);
            localStorage.setItem("usergroup", data.usergroup);
            localStorage.setItem("credentialid", data.id);
            localStorage.setItem("clinics", data.clinics);
            console.log("clinics", data.clinics);
            //Set Credential Id in App.js to credentialid via credentialidhandler
            props.credentialidhandler(data.id);

            //This value is used to switch screens in App.js. So here we pass the appropriate value.
            //sets value to appstate,
            props.apphandler(data.usergroup);

            const userGroup = data.usergroup.toLowerCase(); // assuming usergroup matches the route
            navigate(`/${userGroup}`);
          } else {
            //Stop loading bar if failure and show appropriate error message.
            setloading(false);
            setstatus("failure");
            setmessage("Wrong Username or Password");
            setmessageStatus("error");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    event.preventDefault(); //used to prevent refresh of the page.
  };

  const [showLoginScreen, setShowLoginScreen] = useState(true);
  const [screenType, setScreenType] = useState("login");

  const slidesArray = [
    <CarousalCard
      svgImage={Carousal1}
      title={"Your Digital Clinic"}
      description={
        "Manage your clinic easily from anywhere in the world using our plaform."
      }
    />,
    <CarousalCard
      svgImage={Carousal2}
      title={"Electronic Health Records"}
      description={
        "Seamless and secure Digital Health Records for Streamlined Care."
      }
    />,
    <CarousalCard
      svgImage={Carousal3}
      title={"Patient-Centric Consultations"}
      description={
        "Seamless Doctor-Patient Interaction with easy access to all historical data."
      }
    />,
    <CarousalCard
      svgImage={Carousal4}
      title={"Streamlined Nurse Intake"}
      description={
        "Easy Patient Onboarding for a better Experience: From Check-in to Care."
      }
    />,
  ];

  return (
    <>
      {/* This is the main container of ui elements which contains all the sub objects 
          and applies styles defined in the styles.main class.*/}
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.carousal}>
            <CarouselComponent slides={slidesArray} />
          </div>
        </div>

        {/* This div represents the right section of the login form  */}
        <div className={styles.right}>
          {/* This div is the container for the login form */}
          <div className={styles.major}>
            {showLoginScreen && screenType === "login" ? (
              <>
                <img
                  style={{
                    width: "130px",
                    padding: "20px",
                    paddingTop: "15px",
                  }}
                  src={wellpas_logo}
                  alt="tabeel_logo"
                />

                <div
                  style={{
                    width: "100%",
                    height: "0.2px",
                    backgroundColor: "#c0b7fa",
                    position: "relative",
                  }}
                >
                  <img
                    src={pills}
                    alt="pills"
                    style={{
                      height: "120px",
                      bottom: "0",
                      right: "30px",
                      position: "absolute",
                    }}
                  />
                </div>

                {/* form element wraps the username and password fields. */}
                <form className={styles.formic}>
                  <div className={styles.fieldContainer}>
                    <TextfieldStyled
                      placeholder="exampleuser"
                      name="username"
                      width="100%"
                      color="white"
                      label=""
                      onChangehandler={handlechange}
                      value={loginform.username}
                      error={false}
                      helpertext="Username is required"
                      id="username"
                    />
                  </div>

                  <div className={styles.fieldContainer}>
                    <TextfieldStyled
                      placeholder="***********"
                      type="password"
                      name="password"
                      width="100%"
                      color="white"
                      label=""
                      onChangehandler={handlechange}
                      value={loginform.password}
                      error={false}
                      helpertext="Password is required"
                      id="password"
                    />
                  </div>
                </form>

                {messageStatus !== "" && (
                  <div className={styles.error}>
                    <Error fontSize="small" />
                    <p>Wrong username/password</p>
                  </div>
                )}

                {/* This div contains the login button and a loading indicator. It uses inline styles for layout and spacing. */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    padding: "20px",
                    boxSizing: "border-box",
                  }}
                >
                  {/* This button is used for logging in. When clicked, it calls the handlesubmit function */}
                  <Button
                    name="login"
                    fullWidth
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      boxSizing: "border-box",
                      color: "#6153BD",
                      borderColor: "#6153BD",
                      fontWeight: "300",
                    }}
                    onClick={handlesubmit}
                  >
                    {/* button text changes based on the loading state */}
                    {/* If loading is false, it shows "Login"; otherwise, it shows -> Signing in... 
              with a spinning loader. */}
                    {!loading ? (
                      "LOGIN"
                    ) : (
                      <>
                        Signing in ...{" "}
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            marginLeft: "20px",
                          }}
                        >
                          <Loader color="#6153BD" />
                          {/*loader will shown inside button */}
                        </div>
                      </>
                    )}
                  </Button>
                  {/* This div contains a message and a button for requesting login assistance. */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "grey",
                    }}
                  >
                    <Typography style={{ fontWeight: "200" }} variant="body2">
                      Trouble signing in?
                    </Typography>
                    {/* This button is used for login assistance. When clicked, it sets the 
                dialogVisibility state to true and dialogType to "login" */}
                    <Button
                      onClick={() => {
                        setShowLoginScreen(false);
                        setScreenType("LoginHelp");
                      }}
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        color: "#6153BD",
                      }}
                      variant="text"
                      size="small"
                    >
                      CLICK HERE
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {screenType === "AccessRequest" ? (
                  <RequestAccessNew
                    setShowLoginScreen={setShowLoginScreen}
                    setScreenType={setScreenType}
                  />
                ) : (
                  <LoginHelp
                    setShowLoginScreen={setShowLoginScreen}
                    setScreenType={setScreenType}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* renders Status component and passes status, setstatus, and message props to it */}
      <Status
        actionstatus={status}
        setactionstatus={setstatus}
        message={message}
      />
      <Dialog
        open={dialogVisibility}
        onClose={() => {
          setdialogVisibility(false);
        }}
        keepMounted //This means that the contents of the drawer are always present in the DOM
      >
        {/* renders a MuI Dialog component. It opens when dialogVisibility state is true 
        and contains conditional rendering of either RequestAccess or LoginHelp 
        components based on the dialogType either: access or login*/}
        <DialogContent style={{ padding: "2vw" }}>
          {dialogType === "login" && <LoginHelp />}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Login;
